<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    :retain-focus="false"
    :value="dialog"
    :max-width="1200"
    @input="closeModal(false)"
  >
    <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
          <v-btn
            id="btn-close"
            name="btn-close"
            icon
            @click="closeModal(false)"
          >
            <v-icon>fa-solid fa-xmark</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="b-dialog--content">
          <v-row dense>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <h5 class="b-dialog--content_title">
                Selecione o tipo de plano que você deseja cadastrar
              </h5>
            </v-col>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <v-radio-group
                id="planType"
                name="planType"
                v-model="model.planType"
                row
                class="ma-0"
                required
                :rules="[required]"
              >
                <v-radio
                  label="Saúde"
                  color="secondary"
                  value="Assistência médica"
                ></v-radio>
                <v-radio
                  label="Odontológica"
                  color="secondary"
                  value="Assistência odontológica"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <v-text-field
                id="businessPlanName"
                name="businessPlanName"
                label="Plano comercial"
                outlined
                dense
                required
                :rules="[required]"
                v-model="model.businessPlanName"
              />
            </v-col>
            <v-col cols="12" xl="5" lg="5" md="5" sm="6">
              <v-text-field
                id="ansPlanName"
                name="ansPlanName"
                label="Nome do plano ANS"
                outlined
                dense
                required
                :rules="[required]"
                v-model="model.ansPlanName"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="4" sm="6">
              <BaseAns
                id="ansPlanRegistration"
                name="ansPlanRegistration"
                label="Registro do plano ANS"
                required
                :rules="[required]"
                v-model="model.ansPlanRegistration"
              />
            </v-col>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="3"
              sm="6"
              v-if="model.planType === 'Assistência médica'"
            >
              <v-autocomplete
                id="accommodation"
                name="accommodation"
                label="Acomodação"
                outlined
                dense
                required
                :rules="[required]"
                :items="accommodations"
                v-model="model.accommodation"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <v-autocomplete
                id="coverage"
                name="coverage"
                label="Abrangência"
                outlined
                dense
                required
                :rules="[required]"
                :items="coverages"
                v-model="model.coverage"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6" v-if="edit">
              <v-autocomplete
                id="situation"
                name="situation"
                label="Status"
                outlined
                dense
                required
                :rules="[required]"
                :items="situation"
                v-model="model.situation"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <v-checkbox
                id="hasCoparticipation"
                name="hasCoparticipation"
                label="Possui coparticipação?"
                class="ma-1"
                color="primary"
                v-model="model.hasCoparticipation"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="b-dialog--actions"
          v-permission="{
            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
            operator: '==='
          }"
        >
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            :disabled="!valid"
            :title="edit ? 'Atualizar' : 'Salvar'"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { InsuranceCompanyService } from '@/services/api/insuranceCompany';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    edit: {
      type: Boolean,
      required: true,
      default: false
    },
    planId: {
      type: Object,
      required: true
    },
    document: {
      type: String,
      required: true
    }
  },

  data: () => ({
    valid: true,
    model: {
      id: null,
      planType: null,
      businessPlanName: null,
      ansPlanName: null,
      ansPlanRegistration: null,
      coverage: null,
      accommodation: null,
      hasCoparticipation: false,
      situation: null
    },
    coverages: ['Grupo de municípios', 'Regional', 'Nacional'],
    accommodations: ['Apartamento', 'Enfermaria'],
    situation: ['Ativo', 'Cancelado']
  }),

  created() {
    if (this.edit) {
      this.search();
    }
  },

  methods: {
    closeModal(search) {
      this.$emit('closeModal', search);
    },

    save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      const params = {
        insuranceCompanyDocument: this.document,
        ...this.model
      };

      if (this.edit) {
        this.update(params);
      } else {
        this.create(params);
      }
    },

    async create(params) {
      try {
        const insuranceCompanyService = new InsuranceCompanyService();
        const { status } = await insuranceCompanyService.createPlans(params);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async update(params) {
      try {
        const insuranceCompanyService = new InsuranceCompanyService();
        const { status } = await insuranceCompanyService.updatePlans(params);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async search() {
      try {
        const insuranceCompanyService = new InsuranceCompanyService();
        const { status, data } = await insuranceCompanyService.getPlanById({
          insuranceCompanyDocument: this.document,
          ...this.planId
        });

        if (status === 200) {
          this.model = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
